var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "500px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v("Office / Agent")]),
        _c("div", { staticClass: "content_box" }, [
          _c("h2", { staticClass: "content_title" }, [_vm._v("PUS Office")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.contents,
                expression: "contents",
              },
            ],
            staticClass: "hei200",
            domProps: { value: _vm.contents },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.contents = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _vm._m(0),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Close")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "button lg", attrs: { href: "#" } }, [
      _c("span", { staticClass: "btn_icon print" }),
      _vm._v("Print"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }