import Send from '../../settle.client.js'

export default {
    getOffice (param) {
        return Send({
            url: '/settle/comm/office',
            method: 'get',
            params: {
                portCd: param.portCd
            }
        })
    }
}
