var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "800px", height: "520px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.layerClose()
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [_vm._v(" 파일첨부 ")]),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "content_box" },
          [
            _c("DEXT5Upload", {
              ref: "dextupload",
              attrs: {
                id: "dextupload1",
                category: "",
                width: "100%",
                height: "200px",
                "file-info": _vm.parentInfo,
                "category-info": [
                  { category: "category1", value: "value1" },
                  { category: "category2", value: "value2" },
                ],
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "mt10 text_center" }, [
        _c(
          "a",
          {
            staticClass: "button blue lg",
            on: {
              click: function ($event) {
                return _vm.layerClose()
              },
            },
          },
          [_vm._v("닫기")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", { staticClass: "tbl_row mt10" }, [
      _c("tbody", [
        _c("tr", [_c("th", [_vm._v(" props ")]), _c("td", [_vm._v(" 속성 ")])]),
        _c("tr", [
          _c("th", [_vm._v(" single ")]),
          _c("td", [_vm._v(" 단일 파일일때 추가 ")]),
        ]),
        _c("tr", [
          _c("th", [_vm._v(" category ")]),
          _c("td", [_vm._v(" 카테고리가 있을때 추가 ")]),
        ]),
        _c("tr", [
          _c("th", [
            _vm._v(
              " category-info = list ( JSON ) ex) [{ category:'category1',value:'value1'},{ category:'category2',value:'value2'}] "
            ),
          ]),
          _c("td", [
            _vm._v(
              " 카테고리 있을때 카테고리 리스트를 json 형식으로 category, value 형식으로 전달 "
            ),
          ]),
        ]),
        _c("tr", [
          _c("th", [_vm._v(" file-info = list ( JSON ) ")]),
          _c("td", [
            _vm._v(
              " 파일리스트가 있을때 파일 리스트를 json 형식으로 category, value 형식으로 전달 ex) [{ guid: '', originalName: '', size: '', fileId: '', status: '', category(카테고리있으면):'' , new: }] fileId = 서버의 임시테이블 파일ID 첫 등록이 아니라 전송이 이미 된이후 수정된 상태라면 다운로드받을수있는 url 을 전달해야함 ex)http://dev-api.ekmtc.com/common/commons/tempfile?fileId=218&fileSeq=1 새로 올라간파일은 new: true 가 붙어서 리턴됨 "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }