<template>
  <div class="popup_wrap" style="width:500px;"><!-- 팝업사이즈 참고 : 500*235  popup_wrap -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont" ><!-- popup_cont -->
      <h1 class="page_title">Office / Agent</h1>
      <div class="content_box"><!-- content_box -->
        <h2 class="content_title">PUS Office</h2>
        <textarea class="hei200" v-model="contents"></textarea>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button lg" href="#"><span class="btn_icon print"></span>Print</a><a class="button gray lg" href="#" @click="$emit('close')">Close</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import OfficeAgent from '@/api/rest/settle/officeAgent'

export default {
  name: 'officeAgent',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          portCd: ''
        }
      }
    }
  },
  created () {
  },
  data: function () {
    return {
      param: {
        portCd: ''
      },
      contents: ''
    }
  },
  computed: {
  },
  methods: {
    searchData: function () {
      this.param.portCd = this.parentInfo.portCd //'CHB' //props portCd
      OfficeAgent.getOffice(this.param).then(response => {
        //console.log('response.data=' + JSON.stringify(response))
        this.contents = response.data.rstList[0].sntcCont
        //console.log(JSON.stringify(this.contents))
      }).catch(err => {
        console.log(err)
        //this.contents = 'xxxxxxxxxxxxxxxxxxxxx'
      })
      //console.log('searchData')
    }
  },
  watch: {
  },
  mounted: function () {
    const $vm = this
    //조회
    $vm.searchData()
  }
}
</script>

<style scoped>
</style>
