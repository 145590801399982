<template>
  <div class="popup_wrap" style="width:800px; height:520px;">
    <button
      class="layer_close"
      @click="layerClose()"
    >
      close
    </button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">
        파일첨부
      </h1>
      <table class="tbl_row mt10">
        <tbody>
          <tr>
            <th>
              props
            </th>
            <td>
              속성
            </td>
          </tr>
          <tr>
            <th>
              single
            </th>
            <td>
              단일 파일일때 추가
            </td>
          </tr>
          <tr>
            <th>
              category
            </th>
            <td>
              카테고리가 있을때 추가
            </td>
          </tr>
          <tr>
            <th>
              category-info = list ( JSON ) ex) [{ category:'category1',value:'value1'},{ category:'category2',value:'value2'}]
            </th>
            <td>
              카테고리 있을때 카테고리 리스트를 json 형식으로 category, value 형식으로 전달
            </td>
          </tr>
          <tr>
            <th>
              file-info = list ( JSON )
            </th>
            <td>
              파일리스트가 있을때 파일 리스트를 json 형식으로 category, value 형식으로 전달
              ex) [{ guid: '', originalName: '', size: '', fileId: '', status: '', category(카테고리있으면):'' , new: }]
              fileId = 서버의 임시테이블 파일ID
              첫 등록이 아니라 전송이 이미 된이후 수정된 상태라면 다운로드받을수있는 url 을 전달해야함 ex)http://dev-api.ekmtc.com/common/commons/tempfile?fileId=218&fileSeq=1
              새로 올라간파일은 new: true 가 붙어서 리턴됨
            </td>
          </tr>
        </tbody>
      </table>
      <div class="content_box">
        <DEXT5Upload ref="dextupload" id="dextupload1" category width="100%" height="200px" :file-info="parentInfo" :category-info="[{ category:'category1',value:'value1'},{ category:'category2',value:'value2'}]" />
      </div>
    </div>
    <div class="mt10 text_center">
      <a class="button blue lg" @click="layerClose()">닫기</a>
    </div>
  </div>
</template>

<script>
import DEXT5Upload from '@/components/DEXT5Upload'

export default {
  name: 'SampleDextUploader',
  components: { DEXT5Upload },
  props: {
    parentInfo: {
      type: Array,
      default: function () {
        return []
      }
    },
    init: {
      type: Boolean,
      default: false
    },
    popupType: {
      type: Number
    }
  },
  watch: {
    init: function () {
      console.log('init = ' + this.init)
    },
    parentInfo: function () {
      console.log('parentInfo = ' + JSON.stringify(this.parentInfo))
    }

  },
  created () {
    // test
    //this.fileInfo.guid[i], this.fileInfo.originalName[i], '/', this.fileInfo.size[i], this.fileInfo.fileId[i], uploadId
    // this.fileInfo = [{ guid: '', originalName: '', size: '', fileId: '', status: '' }, { guid: '129C42D8-0F13-AE72-2D32-85D94A8393FC', originalName: '스크린샷 2021-08-04 오후 1.15.45.png', size: '84930', fileId: '124', status: 'complete' }]
  },
  mounted () {
    // const duOptions = {
    //   id: 'dextupload1'
    //
    // }
    // this.$refs.dext.MakeUpload(duOptions)
  },
  methods: {
    setFileInfo: function (parentInfo) {
      console.log('@@@@@ setFileInfo')
      let $vm = this

      // /**/console.log('$vm.refs = ', this.$refs.dextupload.setFileInfo)
      if (this.$refs.dextupload !== undefined) {
        this.$refs.dextupload.setFileInfo(parentInfo)
      }

      // this.parentInfo = parentInfo

      console.log('@@@@@ setFileInfo fileInfo = ' + JSON.stringify(this.parentInfo))
    },
    layerClose: function () {
      console.log('@@@ closw')
      console.log('this.popupType', this.popupType)
      let file = this.$refs.dextupload.getFileInfo()
      this.$refs.dextupload.ResetDext()
      console.log('SampleDextUploader getfileInfo', JSON.stringify(file))
      this.$emit('set-dext-file-info', file, this.popupType)
      this.$ekmtcCommon.layerClose()
    }
  }
}
</script>
