import Send from '../../trans.client.js'

export default {
  // DG/OOG Certificate 파일정보 조회(Booking 등록)
  getCertiFileListForBooking (params) {
    return Send({
      url: '/trans/booking/dg-oog-certi',
      method: 'get',
      params: params
    })
  },
  // DG/OOG Certificate 파일 저장(Booking 등록)
  popDgOogUpload (params) {
    return Send({
      url: '/trans/booking/dg-oog-certi',
      method: 'post',
      data: params
    })
  },
  // DG Certificate 파일정보 조회(SR 등록)
  getCertiFileListForSR (params) {
    return Send({
      url: '/trans/sr/dg-certi',
      method: 'get',
      params: params
    })
  },
  // DG Certificate 파일 저장(SR 등록)
  popDgCertiUpload (params) {
    return Send({
      url: '/trans/sr/dg-certi',
      method: 'post',
      data: params
    })
  },
  // DG/OOG Certificate 파일 저장(Booking Sr 등록)
  bookingSrPopDgOogUpload (params) {
    return Send({
      url: '/trans/booking-sr/dg-oog-certi',
      method: 'post',
      data: params
    })
  },
  getEtcDocFileForBooking (params) {
    return Send({
      url: '/trans/booking/etc-doc',
      method: 'get',
      params: params
    })
  },
  // etc doc 파일 저장
  popEtcDocUpload (params) {
    return Send({
      url: '/trans/booking/etc-doc',
      method: 'post',
      data: params
    })
  }
}
